$(document).ready(function () {

	//Wrap navigation to more dropdown link
	$('#banner .menu ul').navwrap();

	//Agents carousel - homepage
	if ($(".bidx_image_list ol").length > 0) {
		$(".bidx_image_list ol").carouFredSel({
			circular: true,
			infinite: false,
			align: "left",
			width: "100%",
			height: 75,
			items: {
				width: "100px",
				height: "auto"
			},
			scroll: {
				items: 1,
				duration: 1000
			},
			prev: ".prev",
			next: ".next",
		});
	}

	$("li.bidx_image_list img").click(function (e) {
		$(".bidx_image_main").attr('src', $(this).attr('src'));
	});

	//Agents carousel - homepage
	if ($(".detail_mini .bidx_image_list ol").length > 0) {
		$(".detail_mini .bidx_image_list ol").carouFredSel({
			circular: true,
			infinite: false,
			align: "left",
			width: "100%",
			height: 55,
			items: {
				width: "75px",
				height: "auto"
			},
			scroll: {
				items: 1,
				duration: 1000
			},
			prev: ".prev",
			next: ".next",
			responsive: true
		});
	}

	$("li.bidx_image_list img").click(function (e) {
		$(".bidx_image_main").attr('src', $(this).attr('src'));
	});



	// //Mobile navigation
	var $cloneNav = $(".header-right li, .top_nav li").not('.close_icon, .nav-wrap').clone();
	$.each($cloneNav, function () {
		$(this).find('span').remove();
		$(this).find('i').remove();
		$(this).removeAttr('class');
	});
	$("header").prepend('<ul class="mobile-nav"></ul>');
	$cloneNav.appendTo(".mobile-nav");
	$('.mobile-nav').slicknav();

	//to clone detail page comments
	var insertAfterEl = $("#detailprop ul.col2");
	var detailComment = $(".detail_comment").clone();
	var detailCommentprint = $(".detail_comment").clone();
	$(".detail_comment").remove();
	$(detailComment).insertAfter(insertAfterEl);
	$(detailCommentprint).insertAfter($("#detailprop")).addClass("detailCommentprint");

	var commentText = $(detailCommentprint).find("li").text();
	if (commentText.length > 400) {
		commentText = commentText.slice(0, 400) + " ... ";
		$(detailCommentprint).find("li").text(commentText);
	}

	//to add notes heading on flyer
	$(".notes-print").prepend("<h2 class='notes'>Contact Info</h2>");

	if ($(".testimonial-list-container").length > 0) {
		$(".testimonial-list-container .testimonial").show();
		$(".testimonial-list-container").carouFredSel({
			circular: true,
			infinite: false,
			align: "left",
			height: 115,
			responsive: true,
			scroll: {
				items: 1,
				duration: 1000
			},
			pagination: "#foo2_pag"
		});
	}

	//check if homepage do not have content
	if ($.trim($(".pagebody").html()) == '') {
		$(".content-block").hide();
	}

	//header image height on homepage
	var hei = $(".header-height").outerHeight();
	$(".banner-outside-wrap").css("height", hei);
	$(".banner-outside-inner-wrap").css("height", hei);
	$(".banner-fix-height").css("height", hei);
});
